<template>

  <v-dialog
    width="600"
    lazy
    v-model="dialog"
    persistent>

    <v-form>
      <v-card class="section-container">
        <v-icon @click="closeLoanForm()" class="close-button">close</v-icon>
        <v-card-title class="widget__heading">Add a Loaned Player</v-card-title>

        <section class="section-divider">
          <v-layout row>
            <v-flex>
              <label class="label" for="firstName">Status</label>
              <v-select
                v-model="playerData.published"
                item-text='name'
                item-value='value'
                :items="[
                  { name: 'Published', value: 1 },
                  { name: 'Draft', value: 0 }
                ]"
                solo
                flat />
            </v-flex>

          </v-layout>

          <v-layout row>
            <v-flex mr-2 md12>
              <label class="label" for="firstName">Player First Name</label>
              <v-text-field id="firstName" placeholder="First Name" solo flat v-model="playerData.firstName"></v-text-field>
            </v-flex>

            <v-flex ml-2 md12>
              <label class="label" for="lastName">Player Surname*</label>
              <v-text-field id="lastName" placeholder="Surname" solo flat v-model="playerData.lastName"></v-text-field>
              <p><i>*Required - Use the surname field for players who use a nickname or single name only</i></p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-2 md12>
              <label class="label" for="squadNumber">Squad Number</label>
              <v-text-field id="squadNumber" placeholder="Squad Number" solo flat v-model.number="playerData.squadNumber" type="number"></v-text-field>
            </v-flex>

            <v-flex ml-2 md12>
              <label class="label" for="squadPosition">Player Position</label>
              <v-select
                id="squadPosition"
                placeholder="Position"
                solo
                flat
                v-model="playerData.squadPosition"
                :items="['Goalkeeper', 'Defender', 'Midfielder', 'Forward']"></v-select>
            </v-flex>
          </v-layout>

          <v-layout align-center>
            <ImageUploader mb-3 title="Player Image" :widthOptionEnabled="false" :mediaLibraryID="playerData.playerMediaLibraryID" @update="updateImage($event, 'playerImage')" />
          </v-layout>

          <v-layout row mt-4>
            <v-flex mr-2>
              <label class="label" for="loanClub">Club</label>
              <v-text-field id="loanClub" placeholder="Loan Club" solo flat v-model="playerData.loanClub"></v-text-field>
            </v-flex>

            <v-flex ml-2>
              <label class="label" for="loanEnds">Loan Expiry Date</label>
              <v-text-field id="loanEnds" placeholder="YYYY/MM/DD" solo flat v-model="playerData.loanEnds"></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout align-center mb-4>
            <ImageUploader mb-3 title="Club Crest Image" :widthOptionEnabled="false" :mediaLibraryID="playerData.crestMediaLibraryID" @update="updateImage($event, 'crestImage')" />
          </v-layout>

          <v-layout>
            <v-btn
              v-if="newLoanedPlayer"
              flat
              class="submit-button accent"
              @click="createLoanedPlayer();"
            >
              Save
            </v-btn>

            <v-btn
              v-else
              flat
              class="submit-button accent"
              @click="updateLoanedPlayerData();"
            >
              Update
            </v-btn>
          </v-layout>
        </section>

      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
import axios from "axios"
import moment from "moment"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "LoanedPlayer",

  components: {
    ImageUploader
  },

  props: {
    loanedPlayerData: {
      type: Object,
      required: false
    },
    newLoanedPlayer: {
      type: Boolean,
      required: false
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_LOANEDOUTPLAYERS}/loaned-out-players`,
    dialog: true,
    playerData: ""
  }),
  
  methods: {
    createLoanedPlayer() {
      if (!this.playerData.lastName) {
        window.alert("Please enter a surname")
      } else {

        this.$store.commit("startLoading");

        if (this.playerData.loanEnds) {
          this.playerData.loanEnds = moment(this.playerData.loanEnds).format()
        }

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: this.api,
            headers: { "Authorization": jwt },
            data: {
              data: {
                type: "loaned-out-player",
                attributes: this.playerData
              }
            }
          })
          .then(response => {
            console.log("Loan player added", response)
            this.$store.commit("completeLoading");
            this.closeLoanForm();
          })
          .catch(e => {
            console.error("Problem adding loaned player", e);
            this.$store.commit("completeLoading");
          });
        })

      }
    },

    updateLoanedPlayerData() {
      if (!this.playerData.lastName) {
        window.alert("Please enter a surname")
      } else {

        this.$store.commit("startLoading");

        if (this.playerData.loanEnds) {
          this.playerData.loanEnds = moment(this.playerData.loanEnds).format()
        }

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "PATCH",
            url: `${this.api}/${this.loanedPlayerData.id}`,
            headers: { "Authorization": jwt },
            data: {
              data: {
                type: "loaned-out-player",
                id: this.loanedPlayerData.id,
                attributes: this.playerData
              }
            }
          })
          .then(response => {
            console.log("Loan player updated", response)
            this.$store.commit("completeLoading");
            this.closeLoanForm();
          })
          .catch(e => {
            console.error("Problem updating loaned player", e);
            this.$store.commit("completeLoading");
          });
        })

      }
    },

    updateImage(payload, src) {
      console.log("updateImage", payload)

      if (src == "playerImage") {
        this.playerData.playerMediaLibraryID = payload.mediaLibraryID
        this.playerData.playerImageKey = payload.imageKey
      } else if (src == "crestImage") {
        this.playerData.crestMediaLibraryID = payload.mediaLibraryID
        this.playerData.crestImageKey = payload.imageKey
      }
    },

    closeLoanForm() {
      this.dialog = false;
      this.$emit("closeLoanForm");
    }
  },

  mounted() {
    if (!this.newLoanedPlayer) {
      this.playerData = this.loanedPlayerData.attributes;

      if (this.playerData.loanEnds) {
        this.playerData.loanEnds = moment(this.playerData.loanEnds).format("YYYY/MM/DD");
      }
    } else {
      this.playerData = this.loanedPlayerData
    }
  }
}
</script>

<style scoped lang="scss">

.section-container {
  margin: 0;
  padding: 0 32px 16px;
}

.section-divider {
  padding-top: 16px;
}

.submit-button {
  margin: 0;
  margin-left: auto;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

</style>
