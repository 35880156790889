<template>
  <section class="custom-table-item">

    <h3 class="custom-table-item__title">
      {{table.competitionName}}
    </h3>

    <div class="custom-table-item__actions">
      <v-icon class="custom-table-item__action" @click="editTable(table)">edit</v-icon>
      <v-icon class="custom-table-item__action" @click="deleteTable(table.customLeagueTableID)">delete</v-icon>
    </div>

  </section>
</template>

<script>
export default {
  name: "CustomTableItem",

  props: {
    table: {
      type: Object,
      required: true
    }
  },

  methods: {
    editTable(tableData) {
      this.$emit("editTable", tableData)
    },

    deleteTable(id) {
      if(confirm("Are you sure you want to delete this table. This cannot be undone.")) {
        this.$emit("deleteTable", id)
      }
    }
  }
}
</script>

<style scoped lang='scss'>

  .custom-table-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;

    &__title {
      flex-grow: 1;
      padding: 0 0 0 16px;
      color: #67247C;
      font-weight: 500;
    }

    &__actions {
      background-color: #E6E8F1;
      height: 100%;
      padding: 24px 16px;

      :first-child {
        margin-right: 8px;
      }
    }

    &__action {
      color: #67247c;

      &:hover {
        cursor: pointer;
      }
    }
  }

</style>
