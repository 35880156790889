<template>
  <div class="squad-list">

    <loaned-player v-if="selectedPlayerData || newLoanedPlayer" :loanedPlayerData="newLoanedPlayer ? blankPlayerData : selectedPlayerData" :newLoanedPlayer="newLoanedPlayer" @closeLoanForm="resetLoanData()" />

    <section>
      <table class="gc-listing">
        <thead class="gc-listing__headers">
          <th class="gc-listing__header-item"></th>
          <th class="gc-listing__header-item">Published</th>
          <th class="gc-listing__header-item">Squad No</th>
          <th class="gc-listing__header-item">First Name</th>
          <th class="gc-listing__header-item">Surname</th>
          <th class="gc-listing__header-item">Position</th>
          <th class="gc-listing__header-item">Club</th>
          <th class="gc-listing__header-item">Loan Expiry</th>
          <th class="gc-listing__header-item">Actions</th>
        </thead>

        <draggable
          v-if="loanedOutPlayers"
          v-model="loanedOutPlayers"
          tag="tbody"
          handle=".handle"
          @start="drag=true"
          @end="drag=false, status = 'needsSaving'">

          <tr v-for="player in loanedOutPlayers" :key="player.id" class="gc-listing__row">
            <td class="gc-listing__row-item">
              <v-icon class="handle">open_with</v-icon>
            </td>
            <td class="gc-listing__row-item">
              <v-checkbox
                v-model="player.attributes.published"
                disabled
              ></v-checkbox>
            </td>
            <td class="gc-listing__row-item">{{ player.attributes.squadNumber }}</td>
            <td class="gc-listing__row-item">{{ player.attributes.firstName }}</td>
            <td class="gc-listing__row-item">{{ player.attributes.lastName }}</td>
            <td class="gc-listing__row-item">{{ player.attributes.squadPosition }}</td>
            <td class="gc-listing__row-item">{{ player.attributes.loanClub }}</td>
            <td class="gc-listing__row-item">{{ player.attributes.loanEnds | moment('YYYY/MM/DD') }}</td>
            <td class="gc-listing__row-item">
              <v-icon @click="editPlayer(player)">edit</v-icon>
              <v-icon @click="deleteLoanedPlayer(player.id)">delete</v-icon>
            </td>
          </tr>

        </draggable>
      </table>
    </section>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Update loan players'" @buttonClicked="saveLoanedOrder()" :allowPreview="false"/>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
import draggable from "vuedraggable"
import LoanedPlayer from "@/components/Squads/LoanedPlayer"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "SquadList",

  components: {
    draggable,
    LoanedPlayer,
    StickyFooter
  },

  props: {
    teamID: {
      type: String,
      required: true
    },
    newLoanedPlayer: {
      type: Boolean,
      required: false
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_LOANEDOUTPLAYERS}/loaned-out-players`,
    loanedOutPlayers: [],
    errorMessage: "",
    status: "saved",
    selectedPlayerData: "",
    blankPlayerData: ""
  }),

  mounted() {
    this.getPlayers();

    this.blankPlayerData = {
      teamID: this.teamID,
      orderPosition: 0,
      firstName: "",
      lastName: "",
      squadPosition: "",
      squadNumber: null,
      loanClub: "",
      loanEnds: "",
      published: 0,
      playerMediaLibraryID: "",
      playerImageKey: "",
      crestMediaLibraryID: "",
      crestImageKey: ""
    }
  },

  methods: {
    editPlayer(selectedPlayerData) {
      this.selectedPlayerData = selectedPlayerData;
    },

    getPlayers() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/team/${this.teamID}`,
          headers: { "Authorization": jwt },
        })
        .then(response => {
          console.log("Loaned players:", response);
          this.loanedOutPlayers = response.data.data
        })
        .catch(e => {
          console.log("Unable to load loaned players:", e);
        })
      })
    },

    deleteLoanedPlayer(playerID) {
      if (confirm("Are you sure you want to delete this?")) {
        console.warn("Deleting")
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${this.api}/${playerID}`,
            headers: { "Authorization": jwt },
          })
          .then(response => {
            console.log("Loaned player deleted", response);
            this.getPlayers();
          })
          .catch(e => {
            console.error("Problem deleting loaned player", e);
            this.errorMessage = "Problem deleting overlay promo";
          });
        });
      }
    },

    resetLoanData() {
      if (this.newLoanedPlayer) {
        this.$emit("loanedPlayerAdded");
        this.blankPlayerData = {
          teamID: this.teamID,
          orderPosition: 0,
          firstName: "",
          lastName: "",
          squadPosition: "",
          squadNumber: null,
          loanClub: "",
          loanEnds: "",
          published: 0,
          playerMediaLibraryID: "",
          playerImageKey: "",
          crestMediaLibraryID: "",
          crestImageKey: ""
        }
      } else {
        this.selectedPlayerData = "";
      }
      this.getPlayers();
    },

    saveLoanedOrder() {
      this.$store.commit("startLoading");

      const loanedPlayersOrder = this.loanedOutPlayers.map(loanedPlayer => {
        return {
          type: loanedPlayer.type,
          id: loanedPlayer.id
        }
      });

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${this.api}/team/${this.teamID}`,
          headers: { "Authorization": jwt },
          data: {
            data: loanedPlayersOrder
          }
        })
        .then(response => {
          console.log("Loan player updated", response)
          this.$store.commit("completeLoading");
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem updating loaned player", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem updating loaned players";
          this.$store.commit("completeLoading");
        });
      })
    }
  }
}
</script>

<style scoped lang="scss">

.handle {
  cursor: pointer;
}

</style>
