<template>
    <section class="squad">

        <v-tabs v-model="currentTeamIndex">

            <v-tab
                :ripple="{ class: 'accent--text' }"
                v-for="team in teams"
                :key="team.teamID"
                @click="displayLoans = false"
            >{{ team.teamName }}</v-tab>

            <v-tab-item
                v-for="(team, index) in teams"
                :key="`tab-${team.teamID}`"
                px-0
                class="main-body"
            >

                <div class="sub-nav">
                    <span class="sub-nav__tag">Manage:</span>
                    <v-btn
                        @click="toggleTab('displaySquad')"
                        :class="{ 'accent': displaySquad }"
                    >
                        Current Squad
                    </v-btn>
                    <v-btn
                        @click="toggleTab('displayLoans')"
                        :class="{ 'accent': displayLoans }"
                    >
                        Loaned Out
                    </v-btn>
                    <template v-if="featureConfig.default.CustomTables">
                        <v-btn
                            @click="toggleTab('displayTables')"
                            :class="{ 'accent': displayTables }"
                        >Custom Tables
                        </v-btn>
                    </template>
                </div>

                <template v-if="displaySquad">
                    <MiniHeader
                        :heading="`${team.teamName} players - ${team.teamID}`"
                        :buttonText="'Add Player'"
                        :hasButton="true"
                        @buttonClicked="createPlayer()"
                    />

                    <squad-list
                        :teamID="team.teamID"
                        :seasonID="team.seasons[0].seasonID"
                    />
                </template>

                <template v-else-if="displayLoans && currentTeamIndex == index">
                    <MiniHeader
                        heading="Players on loan"
                        :buttonText="'Add Player'"
                        :hasButton="true"
                        @buttonClicked="newLoanedPlayer = true"
                    />

                    <loaned-list
                        :teamID="team.teamID"
                        :newLoanedPlayer="newLoanedPlayer"
                        @loanedPlayerAdded="newLoanedPlayer = false"
                    />
                </template>

                <template v-else-if="displayTables">
                    <MiniHeader
                        :heading="`${team.teamName} custom tables`"
                        :buttonText="'Add Table'"
                        :hasButton="true"
                        @buttonClicked="createTable()"
                    />

                    <custom-tables-list
                        :team="team.teamID"
                        :season="team.seasons[0].seasonID"
                    />
                </template>

            </v-tab-item>

        </v-tabs>

    </section>
</template>

<script>
import axios from "axios"
import CustomTablesList from "@/components/Squads/CustomTablesList"
import LoanedList from "@/components/Squads/LoanedList"
import MiniHeader from "@/components/Library/MiniHeader"
import SquadList from "@/components/Squads/SquadList"

export default {
    name: "SquadManagement",

    components: {
        CustomTablesList,
        LoanedList,
        MiniHeader,
        SquadList
    },

    data: () => ({
        api: `${process.env.VUE_APP_BASEURL}`,
        squadListings: [],
        teams: [],
        currentTeamIndex: 0,
        displaySquad: true,
        displayLoans: false,
        displayTables: false,
        newLoanedPlayer: false,
        // newTable: false,
        // showModal: false,
        // newCustomTable: false
    }),

    created() {
        this.featureConfig = require("@/config/features/" + process.env.VUE_APP_CLUB_ID + "/modules.js")
    },

    mounted() {
        this.getTeams()
    },

    methods: {
        createPlayer() {
            this.$store.commit("startLoading");
            this.$router.push({
                // path: `/player/createplayer/`,
                name: "Player Editor",
                params: {
                    playerID: "createplayer",
                    playerType: "custom",
                    teamID: this.teams[this.currentTeamIndex].teamID,
                    seasonID: this.teams[0].seasons[0].seasonID,
                }
            })
        },

        createTable() {
            this.$store.commit("startLoading");
            this.$router.push({
                name: "Table Editor",
                params: {
                    tableID: "createtable",
                    teamID: this.teams[this.currentTeamIndex].teamID,
                    seasonID: this.teams[0].seasons[0].seasonID,
                }
            })
        },

        getTeams() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.api}/fixtures/opta/filters`, { "headers": { "Authorization": jwt } })
                    .then(response => {
                        console.log("getTeams response: ", response);
                        this.teams = response.data.body

                        if (this.featureConfig.default.LFCExclusive) {
                            this.teams = this.teams.filter(team => team.teamName !== "U23")
                        }

                    })
                    .catch(e => {
                        console.log("Unable to load teams:", e);
                    })
            })
        },

        toggleTab(tab) {
            this.displaySquad = (tab === "displaySquad") ? true : false
            this.displayLoans = (tab === "displayLoans") ? true : false
            this.displayTables = (tab === "displayTables") ? true : false
        }
    }
}
</script>

<style lang="scss" scoped>.sub-nav {
    margin-bottom: 28px;
    text-align: center;

    &__tag {
        margin-right: 8px;
    }
}
</style>
