<template>
  <div class="squad-list">
    <section>
      <table class="gc-listing">
        <thead class="gc-listing__headers">
          <th class="gc-listing__header-item">
            <v-checkbox
              value="true"
              disabled
              readonly
            ></v-checkbox>
          </th>
          <th class="gc-listing__header-item">Type</th>
          <th class="gc-listing__header-item">Player ID</th>
          <th class="gc-listing__header-item">Player Slug</th>
          <th class="gc-listing__header-item">Name</th>
          <th class="gc-listing__header-item">Position</th>
          <th class="gc-listing__header-item">Number</th>
          <th class="gc-listing__header-item">Actions</th>
        </thead>

        <tbody>
          <template v-if="squadListings">
            <template v-for="position in squadPositions">
              <tr v-if="squadListings[position.key]" :key="position.key">
                <td colspan="6" class="cell-heading">{{ position.label }}</td>
              </tr>
              <tr class="gc-listing__row" v-for="player in squadListings[position.key]" :key="player.playerID">
                <template v-if="player.playerID">
                  <td class="gc-listing__row-item">
                    <v-checkbox
                      v-model="player.published"
                      disabled
                    ></v-checkbox>
                  </td>
                  <td class="gc-listing__row-item">Opta</td>
                  <td class="gc-listing__row-item">{{ player.playerID }}</td>
                  <td class="gc-listing__row-item">{{ player.playerSlug }}</td>
                  <td class="gc-listing__row-item player-name">
                    <template v-if="player.knownName || player.knownName_Custom">
                      <template v-if="player.knownName_Custom">
                        {{ player.knownName_Custom}}
                      </template>
                      <template v-else>
                        {{ player.knownName }}
                      </template>
                    </template>

                    <template v-else>
                      <template v-if="player.firstName_Custom">
                        {{ player.firstName_Custom}}
                      </template>
                      <template v-else>
                        {{ player.firstName }}
                      </template>

                      <template v-if="player.surname_Custom">
                        {{ player.surname_Custom}}
                      </template>
                      <template v-else>
                        {{ player.surname }}
                      </template>
                    </template>
                  </td>
                  <td class="gc-listing__row-item">
                    <template v-if="player.position_Custom">
                      {{ player.position_Custom }}
                    </template>
                    <template v-else>
                      {{ player.position }}
                    </template>
                    
                    <i>
                      <template v-if="player.realPosition_Custom">
                        ({{ player.realPosition_Custom }})
                      </template>
                      <template v-else>
                        ({{ player.realPosition }})
                      </template>
                    </i>
                  </td>
                  <td class="gc-listing__row-item">
                    <template v-if="player.shirtNumber_Custom">
                      {{ player.shirtNumber_Custom }}
                    </template>
                    <template v-else>
                      {{ player.shirtNumber }}
                    </template>
                  </td>
                  <td class="gc-listing__row-item">
                    <v-icon @click="editPlayerV3(player.playerID, 'opta')">edit</v-icon>
                  </td>
                </template>

                <template v-else>
                  <td colspan="3" class="error-row">Malformed data for player - please contact your admin</td>
                </template>
              </tr>
            </template>
          </template>

          <template v-if="customSquad">
            <tr>
              <td colspan="6" class="cell-heading">Custom Players</td>
            </tr>

            <tr class="gc-listing__row" v-for="player in customSquad" :key="player.playerID">
              <template v-if="player.playerID">
                <td class="gc-listing__row-item">
                  <v-checkbox
                    v-model="player.published"
                    disabled
                  ></v-checkbox>
                </td>
                <td class="gc-listing__row-item">Custom</td>
                <td class="gc-listing__row-item">{{ player.playerID }}</td>
                <td class="gc-listing__row-item">{{ player.playerSlug }}</td>
                <td class="gc-listing__row-item player-name">
                  <template v-if="player.knownName || player.knownName_Custom">
                    <template v-if="player.knownName_Custom">
                      {{ player.knownName_Custom}}
                    </template>
                    <template v-else>
                      {{ player.knownName }}
                    </template>
                  </template>

                  <template v-else>
                    <template v-if="player.firstName_Custom">
                      {{ player.firstName_Custom}}
                    </template>
                    <template v-else>
                      {{ player.firstName }}
                    </template>

                    <template v-if="player.surname_Custom">
                      {{ player.surname_Custom}}
                    </template>
                    <template v-else>
                      {{ player.surname }}
                    </template>
                  </template>
                </td>
                <td class="gc-listing__row-item">
                  <template v-if="player.position_Custom">
                    {{ player.position_Custom }}
                  </template>
                  <template v-else>
                    {{ player.position }}
                  </template>
                  
                  <i>
                    <template v-if="player.realPosition_Custom">
                      ({{ player.realPosition_Custom }})
                    </template>
                    <template v-else>
                      ({{ player.realPosition }})
                    </template>
                  </i>
                </td>
                <td class="gc-listing__row-item">
                  <template v-if="player.shirtNumber_Custom">
                    {{ player.shirtNumber_Custom }}
                  </template>
                  <template v-else>
                    {{ player.shirtNumber }}
                  </template>
                </td>
                <td class="gc-listing__row-item">
                  <v-icon v-if="defaultSettings.default.playerManagement === 'V3'" @click="editPlayerV3(player.playerID, 'custom')">edit</v-icon>
                  <v-icon v-else @click="editPlayer(player.playerID, 'custom')">edit</v-icon>
                </td>
              </template>

              <template v-else>
                <td colspan="3" class="error-row">Malformed data for player - please contact your admin</td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "SquadList",

  props: {
    teamID: {
      type: String,
      required: true
    },
    seasonID: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    squadListings: [],
    customSquad: [],
    squadPositions: [
      {
        key: "goalkeepers",
        label: "Goalkeepers",
      },
      {
        key: "defenders",
        label: "Defenders",
      },
      {
        key: "midfielders",
        label: "Midfielders",
      },
      {
        key: "forwards",
        label: "Forwards",
      },
      {
        key: "backrows",
        label: "Back Rows",
      },
      {
        key: "halfbacks",
        label: "Half Backs",
      },
      {
        key: "hookers",
        label: "Hookers",
      },
      {
        key: "outsidebacks",
        label: "Outside Backs",
      },
      {
        key: "props",
        label: "Props",
      },
      {
        key: "utilities",
        label: "Utilities",
      }
    ],
    defaultSettings: null
  }),

  created() {
    this.defaultSettings = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
  },

  mounted() {
    this.getPlayers()
    this.getCustomPlayers()
  },

  methods: {
    editPlayer(playerID, playerType) {
      this.$store.commit("startLoading");
      this.$router.push({
        // path: `/player/${playerID}`,
        name: "Player Editor",
        params: {
          playerID: playerID,
          playerType: playerType,
          teamID: this.teamID,
          seasonID: this.seasonID,
        }
      })
    },

    editPlayerV3(playerID, playerType) {
      this.$store.commit("startLoading");
      this.$router.push({
        // path: `/player/${playerID}`,
        name: "Player Editor V3",
        params: {
          playerID: playerID,
          playerType: playerType,
          teamID: this.teamID,
          seasonID: this.seasonID,
        }
      })
    },

    getPlayers() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${this.api}/squads/opta?teamID=${this.teamID}`, { "headers": { "Authorization": jwt }})
          .then(response => {
            console.log(`getPlayers ${this.teamID}`);
            console.log(response);
            this.squadListings = response.data.body
          })
          .catch(e => {
            console.log("Unable to load players:", e);
          })
      })
    },

    getCustomPlayers() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${this.api}/players/custom?teamID=${this.teamID}&seasonID=${this.seasonID}&pageSize=100`, { "headers": { "Authorization": jwt }})
          .then(response => {
            console.log(`getCustomPlayers ${this.teamID}`);
            console.log(response);
            this.customSquad = response.data.body
          })
          .catch(e => {
            console.log("Unable to load players:", e);
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .cell-heading {
    padding: 30px 8px 0;
    color: #24002f;
    font-size: 16px;
    font-weight: 500;
  }

  .player-name {
    color: #67247C;
    font-weight: 500;
  }
</style>
