<template>
  <section v-if="loaded">

    <div v-if="!customTableList.length">
      No Custom Tables Found
    </div>

    <ul v-else>
      <template v-for="table in customTableList">
        <custom-table-item
          v-if="table.teamID === team"
          :key="table.customLeagueTableID"
          :table="table"
          @deleteTable="deleteCustomTable($event)"
          @editTable="editCustomTable($event)" />
      </template>
    </ul>

  </section>

  <p v-else>Loading...</p>

</template>

<script>
import axios from "axios"
import CustomTableItem from "./CustomTableItem"

export default {
  name: "CustomTablesList",

  props: {
    team: {
      type: String,
      required: true
    },
    season: {
      type: Number,
      required: true
    }
  },

  components: {
    CustomTableItem
  },

  data: () => ({
    API: `${process.env.VUE_APP_CUSTOMTABLESAPI}`,
    customTableList: [],
    loaded: false
  }),

  mounted() {
    this.getCustomTables()
  },

  methods: {
    getCustomTables() {
      this.$store.commit("startLoading");
      this.loaded = false
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.API}/leaguetables`, { "headers": { "Authorization": jwt }})
        .then(response => {
          if (response.data.success) {
            this.customTableList = response.data.body
            this.loaded = true
            this.$store.commit("completeLoading");
          }
        })
        .catch(e => {
          console.log("Unable to load custom tables:", e);
        })
      })
    },

    editCustomTable(tableData) {
      this.$store.commit("startLoading")
      this.$router.push(`/team-management/tables/${tableData.customLeagueTableID}`)
    },

    deleteCustomTable(id) {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .delete(`${this.API}/leaguetables?customLeagueTableID=${id}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("deleting table", response)
          if (response.data.success) {
            this.getCustomTables()
          }
        })
        .catch(e => {
          console.log("Unable to delete table:", e);
          this.$store.commit("completeLoading");
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
